import React, {Component} from "react";
import * as auth from "../../store/ducks/auth.duck";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../_metronic";
import {crud, redux} from "@proptrek/pt-web-app-common";

class Logout extends Component {
    componentDidMount() {
        crud.User.logout()
            .then(() => {
                this.props.clearOauth(null);
                this.props.setUser(null);
                this.props.logout();
            })
            .catch((error) => {
                console.error(error);
            });
    }
    
    render() {
        const {hasAuthToken} = this.props;
        
        return hasAuthToken ? <LayoutSplashScreen/> : <Redirect to="/auth"/>;
    }
}

export default connect(
    ({authAdmin}) => ({hasAuthToken: Boolean(authAdmin.authToken)}),
    Object.assign(auth.actions, redux.actions)
)(Logout);
