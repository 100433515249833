import React from 'react';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import Registration from './Registration';

import ForgotPassword from './ForgotPassword';
import Login from './Login';
import {Helmet} from 'react-helmet';
import {toAbsoluteUrl} from '../../../_metronic';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import {QrCodePage} from './QrCodePage';
import ResetPassword from './ResetPassword';
import {VerifiedUser} from "@material-ui/icons";
import UserVerify from "./UserVerify";

export default function AuthPage() {
    const d = new Date();
    const year = d.getFullYear();
    return (
        <>
            {/* https://github.com/nfl/react-helmet */}
            <Helmet>
                {/* <link
            type='text/css'
            rel='stylesheet'
            href={toAbsoluteUrl(
                '/assets/css/demo1/style.bundle.css'
            )}
        />
        <link
          type='text/css'
          rel='stylesheet'
          href={toAbsoluteUrl(
            '/assets/css/demo1/pages/login/login-1.css'
          )}
        /> */}
            </Helmet>

            <div className='kt-grid kt-grid--ver kt-grid--root'>
                <div
                    id='kt_login'
                    className='kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1'
                >
                    <div
                        className='kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile'>
                        <div
                            className='kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside'
                            style={{
                                // backgroundImage: `url(/media/bg/home-background.png)`
                                // backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`
                            }}>

                            {/*<img alt='Proptrek background'*/}
                            {/*     className="background"*/}
                            {/*     srcSet="/assets/images/home-background@2x.png 2x, /assets/images/home-background.png 1x "*/}
                            {/*     src="/assets/images/home-background.png"*/}
                            {/*/>*/}

                            {/*<div className='kt-grid__item'>*/}
                            {/*    <Link to='/' className='kt-login__logo'>*/}
                            {/*        /!*<img alt='Logo' src={'/media/bg/homebackground.jpg'}/>*!/*/}
                            {/*        <img*/}
                            {/*            alt='Logo'*/}
                            {/*            src={toAbsoluteUrl('/media/bg/logo_large_white.svg')}*/}
                            {/*        />*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                            <div className='kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver'>
                                <div className='kt-grid__item kt-grid__item--middle'>
                                    <Link to='/' className='kt-login__logo'>
                                        {/*<img alt='Logo' src={'/media/bg/homebackground.jpg'}/>*/}
                                        <img
                                            alt='Logo'
                                            src={toAbsoluteUrl('/media/bg/logo_large_white.svg')}
                                        />
                                    </Link>
                                    {/*<h3 className='kt-login__title'>PropTrek</h3>*/}
                                    <h4 className='kt-login__subtitle'>
                                        - reverses the search, the property searches for you.
                                    </h4>
                                </div>
                            </div>
                            <div className='kt-grid__item'>
                                <div className='kt-login__info'>
                                    <div className='kt-login__copyright'>
                                        &copy; {year} PropTrek
                                    </div>
                                    {/*<div className='kt-login__menu'>*/}
                                    {/*    <Link to='/terms' className='kt-link'>*/}
                                    {/*        Privacy*/}
                                    {/*    </Link>*/}
                                    {/*    <Link to='/terms' className='kt-link'>*/}
                                    {/*        Legal*/}
                                    {/*    </Link>*/}
                                    {/*    <Link to='/terms' className='kt-link'>*/}
                                    {/*        Contact*/}
                                    {/*    </Link>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>

                        <div
                            className='kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper'>
                            <Switch>
                                <Redirect from='/auth' exact={true} to='/auth/login'/>

                                <Route path='/auth/login' component={Login}/>
                                <Route path='/auth/registration' component={Registration}/>
                                <Route path='/auth/forgot-password' component={ForgotPassword}/>
                                <Route path='/auth/user-verified' component={UserVerify}/>
                                <Route path='/auth/reset-password/:token' component={ResetPassword}/>
                                <Route path='/auth/:mfaSecret/:username' component={QrCodePage}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
