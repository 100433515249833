import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

class UserVerify extends Component {
    
    constructor(props) {
        super(props);
    }
    
    render() {
        const {verified} = new URLSearchParams(window.location.search);
        return (
            <div className='kt-login__body'>
                <div className='kt-login__form'>
                    <div className='kt-login__title'>
                        <h3>
                            <FormattedMessage id='AUTH.RESETPASSWORD.TITLE'/>
                        </h3>
                        <div role='alert' className={`alert alert-${verified == true ? 'succes' : 'danger'}`}>
                            <div className='alert-text'><FormattedMessage
                                id={verified == true ? 'USER_VERIFYCATION_SUCCESS' : 'USER_VERIFYCATION_FAIL'}/></div>
                        </div>
                    </div>
                    <Link to='/auth'
                          className='btn btn-primary btn-elevate kt-login__btn-primary'>
                        Login
                    </Link>
                </div>
            </div>
        );
    }
}

UserVerify.propTypes = {};

export default UserVerify;
