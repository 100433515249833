import React from "react";

export default function OrdersWidget({min, max,average}) {
  return (
    <div className="kt-widget1">
      <div className="kt-widget1__item ng-star-inserted">
        <div className="kt-widget1__info">
          <h3 className="kt-widget1__title">The lowest Credit</h3>
        </div>

        <span className="kt-widget1__number kt-font-danger">{min}</span>
      </div>

      <div className="kt-widget1__item ng-star-inserted">
        <div className="kt-widget1__info">
            <h3 className="kt-widget1__title">The highest Credit</h3>
        </div>
        <span className="kt-widget1__number kt-font-brand">{max}</span>
      </div>

        <div className="kt-widget1__item ng-star-inserted">
            <div className="kt-widget1__info">
                <h3 className="kt-widget1__title">Average of Credits</h3>
            </div>
            <span className="kt-widget1__number kt-font-brand">{average}</span>
        </div>
    </div>
  );
}
