import React from "react";
import {Formik} from "formik";
import {Link, withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import {crud, redux} from "@proptrek/pt-web-app-common";
import * as auth from "../../store/ducks/auth.duck";
import {connect} from "react-redux";

class Registration extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const {intl, history, location} = this.props;
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        return (
            <div className="kt-login__body">
                <div className="kt-login__form">
                    <div className="kt-login__title">
                        <h3>
                            <FormattedMessage id="AUTH.REGISTER.TITLE"/>
                        </h3>
                    </div>
                    
                    <Formik
                        initialValues={{
                            email: "",
                            firstname: "",
                            lastname: "",
                            username: "",
                            password: "",
                            acceptTerms: true,
                            confirmPassword: ""
                        }}
                        validate={values => {
                            const errors = {};
                            
                            if (!values.email) {
                                errors.email = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = intl.formatMessage({
                                    id: "AUTH.VALIDATION.INVALID_FIELD"
                                });
                            }
                            
                            if (!values.firstname) {
                                errors.firstname = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            
                            
                            if (!values.lastname) {
                                errors.lastname = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            
                            if (!values.username) {
                                errors.username = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            
                            if (!values.password) {
                                errors.password = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            if (values.password.match(/(\s+|[^a-zA-Z\d])/g) != null
                                || values.password.match(/([a-z]+)/g) == null
                                || values.password.match(/([A-Z]+)/g) == null
                                || values.password.match(/(\d+)/g) == null
                                || values.password.length < 8) {
                                errors.password = intl.formatMessage({
                                    id: "AUTH.VALIDATION.WRONG_PASSWORD_FORMAT"
                                });
                            }
                            
                            
                            if (!values.confirmPassword) {
                                errors.confirmPassword = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            } else if (values.password !== values.confirmPassword) {
                                errors.confirmPassword =
                                    "Password and Confirm Password didn't match.";
                            }
                            
                            if (!values.acceptTerms) {
                                errors.acceptTerms = "Accept Terms";
                            }
                            
                            return errors;
                        }}
                        onSubmit={(values, {setStatus, setSubmitting}) => {
                            const user = {
                                "email": values.email,
                                "firstName": values.firstname,
                                "lastName": values.lastname,
                                "password": values.password,
                                "roles": [
                                    "USER",
                                    "ADMIN"
                                ],
                                "socialUser": false,
                                "username": values.username
                            };
                            crud.User.registerUserWithToken(user, token)
                                .then(res => {
                                    // console.log(res)
                                    console.log(history);
                                    history.push('/auth/' + res.mfaSecret + '/' + values.username);
                                })
                                .catch(error => {
                                    console.log(error);
                                    setSubmitting(false);
                                    setStatus(
                                        intl.formatMessage({
                                            id: "AUTH.VALIDATION.INVALID_LOGIN"
                                        })
                                    );
                                });
                        }}
                    >
                        {({
                              values,
                              status,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting
                          }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                {status && (
                                    <div role="alert" className="alert alert-danger">
                                        <div className="alert-text">{status}</div>
                                    </div>
                                )}
                                {(token == null) && (
                                    <div role="alert" className="alert alert-danger">
                                        <div className="alert-text"><FormattedMessage
                                            id={'admin_and_content_manager_can_register_only_with_invite_token'}/></div>
                                    </div>
                                )}
                                
                                <div className="form-group mb-0">
                                    <TextField
                                        margin="normal"
                                        label="Firstname"
                                        className="kt-width-full"
                                        name="firstname"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.firstname}
                                        helperText={touched.firstname && errors.firstname}
                                        error={Boolean(touched.firstname && errors.firstname)}
                                        disabled={token == null}
                                    />
                                </div>
                                
                                
                                <div className="form-group mb-0">
                                    <TextField
                                        margin="normal"
                                        label="Lastname"
                                        className="kt-width-full"
                                        name="lastname"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.lastname}
                                        helperText={touched.lastname && errors.lastname}
                                        error={Boolean(touched.lastname && errors.lastname)}
                                        disabled={token == null}
                                    />
                                </div>
                                
                                <div className="form-group mb-0">
                                    <TextField
                                        label="Email"
                                        margin="normal"
                                        className="kt-width-full"
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        helperText={touched.email && errors.email}
                                        error={Boolean(touched.email && errors.email)}
                                        disabled={token == null}
                                    />
                                </div>
                                
                                <div className="form-group mb-0">
                                    <TextField
                                        margin="normal"
                                        label="Username"
                                        className="kt-width-full"
                                        name="username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.username}
                                        helperText={touched.username && errors.username}
                                        error={Boolean(touched.username && errors.username)}
                                        disabled={token == null}
                                    />
                                </div>
                                
                                <div className="form-group mb-0">
                                    <TextField
                                        type="password"
                                        margin="normal"
                                        label="Password"
                                        className="kt-width-full"
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                        helperText={touched.password && errors.password}
                                        error={Boolean(touched.password && errors.password)}
                                        disabled={token == null}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <TextField
                                        type="password"
                                        margin="normal"
                                        label="Confirm Password"
                                        className="kt-width-full"
                                        name="confirmPassword"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.confirmPassword}
                                        helperText={touched.confirmPassword && errors.confirmPassword}
                                        error={Boolean(
                                            touched.confirmPassword && errors.confirmPassword
                                        )}
                                        disabled={token == null}
                                    />
                                </div>
                                
                                <div className="form-group mb-0">
                                    <FormControlLabel
                                        label={
                                            <>
                                                I agree the{" "}
                                                <Link
                                                    to="/terms"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Terms & Conditions
                                                </Link>
                                            </>
                                        }
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name="acceptTerms"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                checked={values.acceptTerms}
                                                disabled={token == null}
                                            />
                                        }
                                    />
                                </div>
                                
                                <div className="kt-login__actions">
                                    <Link
                                        to="/auth/forgot-password"
                                        className="kt-link kt-login__link-forgot"
                                    >
                                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON"/>
                                    </Link>
                                    
                                    <Link to="/auth">
                                        <button type="button"
                                                className="btn btn-secondary btn-elevate kt-login__btn-secondary">
                                            Back
                                        </button>
                                    </Link>
                                    
                                    <button
                                        disabled={isSubmitting || !values.acceptTerms || token == null}
                                        className="btn btn-primary btn-elevate kt-login__btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    }
}

export default withRouter(injectIntl(connect(null, auth.actions)(Registration)));
