import React from 'react';
import QrCode from 'qrcode.react';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

export class QrCodePage extends React.Component {
    
    constructor(props) {
        super(props);
        
    }
    
    componentDidMount() {
    
    }
    
    render() {
        const {match: {params}} = this.props;
        let payload = 'otpauth://totp/[[' + params.username + ']]?secret=[[' + params.mfaSecret + ']]&issuer=[[PropTrek]]';
        return (
            <div className="kt-login__body">
                <div className="kt-login__form">
                    <div className="kt-login__title">
                        <h3>
                            <FormattedMessage id="AUTH.MFA.TITLE"/>
                        </h3>
                    </div>
                    <div>
                        <h4><FormattedMessage id="USERNAME"/>: {params.username}</h4>
                    </div>
                    <div>
                        <h4><FormattedMessage id="AUTH.MFA.CODE"/>: {params.mfaSecret}</h4>
                    </div>
                    <div>
                        <QrCode value={payload} size={190}/>
                    </div>
                    <div>
                        <Link to={'/auth/login'}
                              className='btn btn-primary btn-elevate kt-login__btn-primary'>
                            Go to login
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
    
    
}

export default QrCodePage;
