import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import DocsPage from "./docs/DocsPage";
import {LayoutSplashScreen} from "../../../_metronic";

const GoogleMaterialPage = lazy(() =>
    import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
    import("./react-bootstrap/ReactBootstrapPage")
);
const EditProfilePage = lazy(() =>
    import("./profile/EditProfilePage")
);
const UsersPage = lazy(() =>
    import("../user/UsersPage")
);
const RealEstatePage = lazy(() =>
    import("../real-estate/RealEstatePage")
);
const AgencyPage = lazy(() =>
    import("../agency/AgencyPage")
);
const BlogPage = lazy(() =>
    import("../content/blog/BlogPage")
);
const NewsLetterPage = lazy(() =>
    import("../content/newsletter/NewsLetterPage")
);
const FeedbackPage = lazy(() =>
    import("../content/feedback/FeedbackPage")
);
const ChatPage = lazy(() =>
    import("../chat/ChatPage")
);
const DocumentPage = lazy(() =>
    import("../content/document/DocumentPage")
);
const VisitDuration = lazy(() =>
    import("../visit-duration/VisitDuration")
);
const Packages = lazy(() =>
    import("../packages/PackagePage")
);
const CreditTypes = lazy(() =>
    import("../creditTypes/CreditType")
);
const Payments = lazy(() =>
    import("../payments/Payments")
);
const DeleteRequest = lazy(() =>
    import("../delete-request/DeleteRequest")
);
export default function HomePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <Route path="/builder" component={Builder}/>
                <Route path="/dashboard" component={Dashboard}/>
                <Route path="/profile" component={EditProfilePage}/>
                <Route path="/packages" component={Packages}/>
                <Route path="/credit-types" component={CreditTypes}/>
                <Route path="/payments" component={Payments}/>
                <Route exact={true} path="/chat" component={ChatPage}/>
                <Route path="/chat/:chatRoomId" component={ChatPage}/>
                <Route path="/real-estates" component={RealEstatePage}/>
                <Route path="/visit-duration" component={VisitDuration}/>
                <Route path="/agency" component={AgencyPage}/>
                <Route path="/newsletters" component={NewsLetterPage}/>
                <Route path="/feedbacks" component={FeedbackPage}/>
                <Route path="/document" component={DocumentPage}/>
                <Route path="/blogs" component={BlogPage}/>
                <Route path="/users" component={UsersPage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/docs" component={DocsPage}/>
                <Route path="/deleteRequest" component={DeleteRequest}/>
                <Redirect to="/error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
