module.exports = {
    backend: "https://backend.proptrek.com",
    oauthBackend: "https://backend.proptrek.com/pt-user",
    baseUrl: 'https://admin.proptrek.com',
    invoiceUrl: '//invoice.proptrek.com',
    clientId: "admin",
    clientSecret: "Z_*f*g-yglH+hH&!3J=enanp*18OlMsZ",
    clientType: "admin",
    version: '0.0.1',
    env: 'production'
};
