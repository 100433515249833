import createSagaMiddleware from "redux-saga";

import { reducers, rootSaga } from "./rootDuck";


import {init, clientTypes} from "@proptrek/pt-web-app-common";
import config from "../../config";

const sagaMiddleware = createSagaMiddleware();

const storeConfig = init(config, clientTypes.WEB, localStorage, reducers, sagaMiddleware, rootSaga);

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = storeConfig.persistor;

sagaMiddleware.run(rootSaga);

export default storeConfig.store;
