import React from "react";

export default function PriceValueWidget({min, sum, max,average}) {
    return (
        <div className="kt-widget1">
            <div className="kt-widget1__item ng-star-inserted">
                <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title">Summa price</h3>
                </div>

                <span className="kt-widget1__number kt-font-danger">{new Intl.NumberFormat("en-EN",{
                    style: "currency", currency: 'EUR'}).format(sum)}</span>
            </div>
            <div className="kt-widget1__item ng-star-inserted">
                <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title">Minimum Price</h3>
                </div>

                <span className="kt-widget1__number kt-font-danger">{new Intl.NumberFormat("en-EN",{
                    style: "currency", currency: 'EUR'}).format(min)}</span>
            </div>

            <div className="kt-widget1__item ng-star-inserted">
                <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title">Maximum Price</h3>
                </div>

                <span className="kt-widget1__number kt-font-brand">{new Intl.NumberFormat("en-EN",{
                    style: "currency", currency: 'EUR'}).format(max)}</span>

            </div>

            <div className="kt-widget1__item ng-star-inserted">
                <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title">Average Price</h3>
                </div>
                <span className="kt-widget1__number kt-font-brand">{new Intl.NumberFormat("en-EN",{
                    style: "currency", currency: 'EUR'}).format(average)}</span>
            </div>
        </div>
    );
}
