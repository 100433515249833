import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";
import {metronic} from "../../../_metronic";
import ReactGA from 'react-ga';
import OrdersWidget from "../../widgets/OrdersWidget";
import PriceValueWidget from "../../widgets/PriceValueWidget";
import RoomCountWidget from "../../widgets/RoomCountWidget";
import {crud} from "@proptrek/pt-web-app-common";
import Chart from "react-google-charts";
import Article from "../../../assets/Article.svg";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    ActiveUsersChart,
    AnalyticsDashboard, BarChart,
    BounceRateChart, GeoChart,
    OrganicSearchesChart,
    PagesPerSessionChart,
    PageViewsPerPathChart,
    SessionDurationChart,
    SessionsByDateChart,
    SessionsByDeviceCategoryChart,
    SessionsBySourceChart,
    SessionsByUserTypeChart,
    SessionsGeoChart
} from "react-analytics-charts";
import {withRouter} from "react-router-dom";

const Dashboard = withRouter(
    injectIntl(props => {
// export default function Dashboard() {
    const [userStatistics, setUserStatistics] = useState([]);
    const [realEstateStatistics, setRealEstateStatistics] = useState([]);
    const [saleType, setSaleType] = useState([]);
    const [condition, setCondition] = useState([]);
    const [heating, setHeating] = useState([]);
    const [creditCount, setcreditCount] = useState([]);
    const [types, setTypes] = useState([]);
    const [content, setContent] = useState([]);
    let sumRealestate = 0;
    const [data, setData] = useState([]);
    const {brandColor, dangerColor, successColor, primaryColor} = useSelector(
        state => ({
            brandColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.brand"
            ),
            dangerColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.danger"
            ),
            successColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.success"
            ),
            primaryColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.primary"
            )
        })
    );

    useEffect(() => {
        crud.Statistic.getEstatesStatistics().then(response => {
            setTypes(response.type.values);
        });

        // crud.RealEstate.listingTypeCount(null, []).then(response => {
        //     // setTypes(response);
        // });
    }, []);

    useEffect(() => {
        crud.Statistic.getContentStatistics().then(response => {
            setContent(response.types.values);
        });

        crud.Statistic.getUserStatistics().then(response => {
            setUserStatistics(response);
            setcreditCount(response.creditCount);
        });
        crud.Statistic.getEstatesStatistics().then(response => {
            setRealEstateStatistics(response);

            setSaleType(response.saleType.values);
            setCondition(response.condition.values);
            setHeating(response.heating.values);
        });
    }, []);
    const getTypes = () => Object.keys(types).map(item => [props.intl.formatMessage({id:item}), types[item]]);

    const getHeating = () => Object.keys(heating).map(item => [props.intl.formatMessage({id:item}), heating[item]]);

    const saleTypes = () =>
        Object.keys(saleType).map(item => [props.intl.formatMessage({id:item}), saleType[item]]);
    const getCondition = () => Object.keys(condition).map(item => [props.intl.formatMessage({id:item}), condition[item]]);



    const getContent = () =>
        <div className="double-width row feedback">
            {Object.keys(content).map(key => {
                return (
                    <div className="col-xl-6" key={key}>
                        <div className="card card-custom bgi-no-repeat card-stretch gutter-b">
                            <div className="card-body">
              <span className="svg-icon svg-icon-2x svg-icon-info">
                <img src={Article} alt="Blog"/>
              </span>
                                <span
                                    className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                {content[key]}
              </span>
                                <span className="font-weight-bold text-muted font-size-sm">
                 {key}
              </span>
                            </div>
                        </div>
                    </div>
                );
            })}</div>


    const getUserCreditCount = () =>
        userStatistics && userStatistics.creditCount ? (
            <OrdersWidget
                min={userStatistics.creditCount.min}
                max={userStatistics.creditCount.max}
                average={userStatistics.creditCount.average.toFixed(3)}
            />
        ) : (
            ""
        );

    const priceValue = () => realEstateStatistics && realEstateStatistics.priceValue ? (
        <PriceValueWidget
            min={realEstateStatistics.priceValue.min}
            max={realEstateStatistics.priceValue.max}
            sum={realEstateStatistics.priceValue.sum}
            average={realEstateStatistics.priceValue.average.toFixed(3)}
        />
    ) : ("");


    const getRoomCount = () => realEstateStatistics && realEstateStatistics.roomCount ? (
            <RoomCountWidget
                min={realEstateStatistics.roomCount.min}
                max={realEstateStatistics.roomCount.max}
                average={realEstateStatistics.roomCount.average.toFixed(3)}
                sum={realEstateStatistics.roomCount.sum}
            />
        ) : ("")
    ;

    return (
        <div className='main-grid'>
            <AnalyticsDashboard
                authOptions={{clientId: "247790040482-5vc0f1p01k9kavs28fs76bk4op3obkit.apps.googleusercontent.com"}}
                renderCharts={(gapi, viewId) => {
                    return (
                        <React.Fragment>
                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Active UsersChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>
                                    <BarChart
                                        gapi={gapi}
                                        query={{
                                            ids: viewId,
                                            'start-date': '30daysAgo',
                                            'end-date': 'today',
                                            metrics: 'ga:sessions,ga:users,ga:pageviews',
                                            dimensions: 'ga:deviceCategory',
                                        }}
                                        container="traffic-by-device-category-chart"
                                        options={{
                                            title: 'Traffic By Device Category (30 Days)',
                                        }}
                                    /> </PortletBody>
                            </Portlet>

                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Active UsersChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>
                                    <GeoChart
                                        gapi={gapi}
                                        query={{
                                            metrics: 'ga:sessions,ga:pageviews',
                                            dimensions: 'ga:country',
                                            'start-date': `28daysAgo`,
                                            'end-date': 'today',
                                            ids: viewId,
                                        }}
                                        container="traffic-geo-chart"
                                    />
                                </PortletBody>
                            </Portlet>

                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Active UsersChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>
                                    <ActiveUsersChart
                                        gapi={gapi}
                                        viewId={viewId}
                                        days={28}
                                        activeUserDays={7}
                                    />
                                </PortletBody>
                            </Portlet>

                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Sessions by DateChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>
                                    <SessionsByDateChart
                                        gapi={gapi}
                                        viewId={viewId}
                                        showPageViews
                                        showUsers
                                    />
                                </PortletBody>
                            </Portlet>

                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Sessions GeoChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>
                                    <SessionsGeoChart
                                        gapi={gapi}
                                        viewId={viewId}
                                        showPageViews
                                        query={{
                                            metrics: 'ga:sessions,ga:pageviews',
                                            dimensions: 'ga:country',
                                            'start-date': `28daysAgo`,
                                            'end-date': 'today',
                                            ids: viewId,
                                        }}
                                        container="traffic-geo-chart"
                                    />
                                </PortletBody>
                            </Portlet>

                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Sessions by SourceChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>
                                    <SessionsBySourceChart
                                        gapi={gapi}
                                        viewId={viewId}
                                        style={{margin: '15px', maxWidth: 400}}
                                    />
                                </PortletBody>
                            </Portlet>


                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Sessions by SourceChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>
                                    <SessionsBySourceChart
                                        gapi={gapi}
                                        viewId={viewId}
                                    />
                                </PortletBody>
                            </Portlet>

                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="PageViews per PathChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>

                                    <PageViewsPerPathChart
                                        gapi={gapi}
                                        viewId={viewId}
                                    />

                                </PortletBody>
                            </Portlet>

                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Sessions by DeviceCategoryChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>
                                    <SessionsByDeviceCategoryChart
                                        gapi={gapi}
                                        viewId={viewId}
                                    />
                                </PortletBody>
                            </Portlet>

                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Sessions By UserTypeChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>

                                    <SessionsByUserTypeChart
                                        gapi={gapi}
                                        viewId={viewId}
                                    />

                                </PortletBody>
                            </Portlet>

                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Sessions DurationChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>
                                    <SessionDurationChart
                                        gapi={gapi}
                                        viewId={viewId}
                                    />
                                </PortletBody>
                            </Portlet>

                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Pages per SessionChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>

                                    <PagesPerSessionChart
                                        gapi={gapi}
                                        viewId={viewId}
                                    />

                                </PortletBody>
                            </Portlet>

                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Organic Searches Chart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>
                                    <OrganicSearchesChart
                                        gapi={gapi}
                                        viewId={viewId}
                                    />
                                </PortletBody>
                            </Portlet>

                            <Portlet fluidHeight={true}>
                                <PortletHeader
                                    title="Bounce RateChart"
                                    toolbar={<PortletHeaderToolbar/>}
                                />
                                <PortletBody>

                                    <BounceRateChart
                                        gapi={gapi}
                                        viewId={viewId}
                                    />

                                </PortletBody>
                            </Portlet>

                        </React.Fragment>)


                }}
            />


            <div className="nested-grid">
                <Portlet fluidHeight={true}>
                    <PortletHeader
                        title="Type of Properties"
                        toolbar={<PortletHeaderToolbar></PortletHeaderToolbar>}
                    />

                    <PortletBody>
                        <div className="card-body">
                            <div className="separator separator-dashed my-7"></div>
                            <table className="table table-striped table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Property Type</th>
                                    <th>Count</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(types).map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <th>{index}</th>
                                            <td><FormattedMessage id={item}/></td>
                                            {/*<td>{item.toLowerCase()}</td>*/}
                                            <td>{types[item]}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </PortletBody>
                </Portlet>


                <Portlet fluidHeight={true}>
                    <PortletHeader
                        title="Current status"
                        toolbar={<PortletHeaderToolbar></PortletHeaderToolbar>}
                    />
                    <PortletBody>
                        <Chart
                            height={'100%'}
                            width={'100%'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={[["types", "count"], ...getTypes()]}
                            options={{
                                title: "Barchart of Property Types\n",
                                sliceVisibilityThreshold: 0.03 // 1%
                            }}
                            rootProps={{"data-testid": "7"}}
                        />
                    </PortletBody>
                </Portlet>


                {getContent()}

                <Portlet fluidHeight={true}>

                    <PortletBody>
                        <Chart

                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={[["types", "count"], ...saleTypes()]}
                            options={{
                                title: "Barchart of the Offer",
                                sliceVisibilityThreshold: 0.03 // 1%
                            }}
                            rootProps={{"data-testid": "7"}}
                        />
                    </PortletBody>
                </Portlet>

                <Portlet fluidHeight={true}>

                    <PortletBody>
                        <Chart
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={[["types", "count"], ...getCondition()]}
                            options={{
                                title: "Barchart of the Condition",
                                sliceVisibilityThreshold: 0.2 // 1%
                            }}
                            rootProps={{"data-testid": "7"}}
                        />
                    </PortletBody>
                </Portlet>


                <Portlet className="double-width">
                    <PortletBody fit={true}>
                        <div className="row row-no-padding row-col-separator-xl">
                            <div className="col-xl-4">
                                {getUserCreditCount()}
                            </div>
                            <div className="col-xl-4">
                                {getRoomCount()}
                            </div>
                            <div className="col-xl-4">
                                {priceValue()}
                            </div>
                        </div>
                    </PortletBody>
                </Portlet>

                <Portlet fluidHeight={true}>
                    <PortletHeader
                        title="Heating type statistics"
                        toolbar={<PortletHeaderToolbar></PortletHeaderToolbar>}
                    />

                    <PortletBody>
                        <div className="card-body">
                            <div className="separator separator-dashed my-7"></div>
                            <table className="table table-striped table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Heat Name</th>
                                    <th>Count</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(heating).map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <th>{index}</th>
                                            <td>{<FormattedMessage id={item}/>}</td>
                                            <td>{heating[item]}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </PortletBody>
                </Portlet>

                <Portlet fluidHeight={true}>
                    <PortletHeader
                        title="Heating diagram"
                        toolbar={<PortletHeaderToolbar></PortletHeaderToolbar>}
                    />
                    <PortletBody>
                        <Chart
                            height={'100%'}
                            width={'100%'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={[["types", "count"], ...getHeating()]}
                            options={{
                                title: "Popularity of Types Heating",
                                sliceVisibilityThreshold: 0.05
                            }}
                        />
                    </PortletBody>
                </Portlet>

            </div>
        </div>

    ) ;}))


export default injectIntl(Dashboard);
