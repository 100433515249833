/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {withLastLocation} from 'react-router-last-location';
import HomePage from '../pages/home/HomePage';
import AuthPage from '../pages/auth/AuthPage';
import ErrorsPage from '../pages/errors/ErrorsPage';
import LogoutPage from '../pages/auth/Logout';
import {LayoutContextProvider} from '../../_metronic';
import * as routerHelpers from '../router/RouterHelpers';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import {connect} from 'react-redux';
import FirebaseServices from '../utils/FirebaseServices';

let firebaseServices = new FirebaseServices();

class Routes extends Component {
    
    componentDidMount() {
        firebaseServices.init();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        firebaseServices.init();
    }
    
    render() {
        const {
            isAuthorized,
            menuConfig,
            userLastLocation,
            Layout,
            history,
            lastLocation
        } = this.props;
        routerHelpers.saveLastLocation(lastLocation);
        
        return (
            /* Create `LayoutContext` from current `history` and `menuConfig`. */
            <LayoutContextProvider history={history} menuConfig={menuConfig}>
                <Switch>
                    {!isAuthorized ? (
                        /* Render auth page when user at `/auth` and not authorized. */
                        <Route path='/auth' component={AuthPage}/>
                    ) : (
                        /* Otherwise redirect to root page (`/`) */
                        <Redirect from='/auth' to={userLastLocation}/>
                    )}
                    
                    <Route path='/error' component={ErrorsPage}/>
                    <Route path='/logout' component={LogoutPage}/>
                    
                    {!isAuthorized ? (
                        /* Redirect to `/auth` when user is not authorized */
                        <Redirect to='/auth/login'/>
                    ) : (
                        <Layout>
                            <HomePage userLastLocation={userLastLocation}/>
                        </Layout>
                    )}
                </Switch>
                <ToastContainer
                    position='top-right'
                    autoClose={false}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </LayoutContextProvider>
        );
    }
}

Routes.propTypes = {};

const mapStateToProps = ({auth, urls, builder: {menuConfig}}) => ({
    menuConfig,
    isAuthorized: auth.auth != null,
    userLastLocation: routerHelpers.getLastLocation()
});

export default connect(mapStateToProps)(withLastLocation(withRouter(Routes)));