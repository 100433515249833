import React, {Fragment} from 'react';
import 'firebase/messaging';
import {FirebaseService, utils} from '@proptrek/pt-web-app-common';
import {toast} from 'react-toastify';
import {FormattedMessage} from 'react-intl';

let firebaseServices = null;

export const notificationCategories = {
    CHAT: 'CHAT',
    GEO: 'GEO',
    NEWSLETTER: 'NEWSLETTER',
    CALENDAR: 'CALENDAR',
    FAVORITE_REAL_ESTATE: 'FAVORITE_REAL_ESTATE',
    FAVORITE_SEARCH: 'FAVORITE_SEARCH',
    APP_NEWS: 'APP_NEWS',
    CREDITS: 'CREDITS',
    LISTING: 'LISTING',
    PAYMENT: 'PAYMENT',
    ANY: 'ANY'
};

let notiObservables = {
    CHAT: [],
    GEO: [],
    NEWSLETTER: [],
    CALENDAR: [],
    FAVORITE_REAL_ESTATE: [],
    FAVORITE_SEARCH: [],
    APP_NEWS: [],
    CREDITS: [],
    LISTING: [],
    PAYMENT: [],
    ANY: []
};

export default class FirebaseServices {

    constructor() {
        firebaseServices = new FirebaseService();
        firebaseServices.onMessageReceived = (payload) => {
            console.log('Firebase Message received. ', payload);

            const {TARGET_ID: targetId, CATEGORY: category, MESSAGE: message, DATA: data, ICON: icon, TITLE: title}
                = payload.data;

            let titleString = payload.notification.title;
            let linkTo = '/notifications';

            if (category === notificationCategories.CHAT) {
                const {from, isFile} = JSON.parse(data);
                linkTo = '/chat';
                titleString = (
                    <Fragment>
                        {(from.profilePicture) && (
                            <img alt={'profile picture'} className={'toast-image'}
                                 src={utils.URLS.getFileUrl(from.profilePicture, 'user/')}/>
                        )}
                        <span className={'toast-text'}>
                            <FormattedMessage
                                id={isFile ? 'new_file_from' : 'new_message_from'}/>{` ${from.firstName} ${from.lastName}`}
                        </span>
                    </Fragment>
                )
                if (targetId != null) {
                    linkTo += `/${targetId}`;
                }
                console.log(titleString)
                toast(<a href={linkTo}>{titleString}</a>, {autoClose: 6000});
                FirebaseServices.observ(category);
            }
        }
    }

    init() {
        firebaseServices.init();
    }

    static subscribe(category, callbackObject) {
        notiObservables[category].push(callbackObject);
    }

    static unSubscribe(category, callbackObjectId) {
        notiObservables[category] = notiObservables[category].filter((callbackObject) => {
            return callbackObject.id !== callbackObjectId
        });
    }

    static observ(category) {
        Object.keys(notiObservables).forEach((categ) => {
            notiObservables[categ].forEach((callbackObject) => {
                if (categ === category || categ === 'ANY') {
                    callbackObject.callback();
                }
            })
        });
    }

}
