import React from "react";
import {Formik} from "formik";
import {connect} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {TextField} from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import {crud} from "@proptrek/pt-web-app-common";

function ResetPassword(props) {
    const {intl} = props;
    const {token} = useParams();
    return (
        <div className="kt-login__body">
            <div className="kt-login__form">
                <div className="kt-login__title">
                    <h3>
                        <FormattedMessage id="AUTH.RESETPASSWORD.TITLE"/>
                    </h3>
                </div>
                
                <Formik
                    initialValues={{
                        password: "",
                        confirmPassword: ""
                    }}
                    validate={values => {
                        const errors = {};
                        
                        if (!values.password) {
                            errors.password = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else if (values.password.match(/(\s+|[^a-zA-Z\d])/g) != null
                            || values.password.match(/([a-z]+)/g) == null
                            || values.password.match(/([A-Z]+)/g) == null
                            || values.password.match(/(\d+)/g) == null
                            || values.password.length < 8) {
                            errors.password = intl.formatMessage({
                                id: "AUTH.VALIDATION.WRONG_PASSWORD_FORMAT"
                            });
                        }
                        
                        if (!values.confirmPassword) {
                            errors.confirmPassword = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else if (values.password !== values.confirmPassword) {
                            errors.confirmPassword =
                                "Password and Confirm Password didn't match.";
                        }
                        
                        return errors;
                    }}
                    onSubmit={(values, {setStatus, setSubmitting}) => {
                        crud.User.updatePassword(
                            token,
                            values.password
                        ).then(res => {
                            props.history.push('/auth');
                        }).catch(error => {
                            console.log(error);
                            setSubmitting(false);
                            setStatus(
                                intl.formatMessage({
                                    id: "AUTH.RESETPASSWORD.INVALID"
                                })
                            );
                        });
                    }}
                >
                    {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            {status && (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}
                            
                            <div className="form-group mb-0">
                                <TextField
                                    type="password"
                                    margin="normal"
                                    label="Password"
                                    className="kt-width-full"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    helperText={touched.password && errors.password}
                                    error={Boolean(touched.password && errors.password)}
                                />
                            </div>
                            
                            <div className="form-group">
                                <TextField
                                    type="password"
                                    margin="normal"
                                    label="Confirm Password"
                                    className="kt-width-full"
                                    name="confirmPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                    error={Boolean(
                                        touched.confirmPassword && errors.confirmPassword
                                    )}
                                />
                            </div>
                            
                            <div className="kt-login__actions">
                                
                                <Link to="/auth">
                                    <button type="button"
                                            className="btn btn-secondary btn-elevate kt-login__btn-secondary">
                                        Back
                                    </button>
                                </Link>
                                
                                <button
                                    disabled={isSubmitting}
                                    className="btn btn-primary btn-elevate kt-login__btn-primary"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default injectIntl(
    connect(
        null,
        auth.actions
    )(ResetPassword)
);
