import React from "react";

export default function RoomCountWidget({min, sum, max,average}) {
    return (
        <div className="kt-widget1">
            <div className="kt-widget1__item ng-star-inserted">
                <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title">Room count</h3>
                </div>

                <span className="kt-widget1__number kt-font-danger">{sum}</span>
            </div>
            <div className="kt-widget1__item ng-star-inserted">
                <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title">Minimum Room</h3>
                </div>

                <span className="kt-widget1__number kt-font-danger">{min}</span>
            </div>

            <div className="kt-widget1__item ng-star-inserted">
                <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title">Maximum Room</h3>
                </div>
                <span className="kt-widget1__number kt-font-brand">{max}</span>
            </div>

            <div className="kt-widget1__item ng-star-inserted">
                <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title">Average of Rooms</h3>
                </div>
                <span className="kt-widget1__number kt-font-brand">{average}</span>
            </div>
        </div>
    );
}
