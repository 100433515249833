import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import {FormattedMessage, injectIntl} from 'react-intl';
import clsx from 'clsx';
import {crud} from '@proptrek/pt-web-app-common';
import * as auth from "../../store/ducks/auth.duck";
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            mfa: null,
            loadingButtonStyle: {paddingRight: '2.5rem'}
        }
    }

    enableLoading = () => {
        this.setState({
            loading: true,
            loadingButtonStyle: {paddingRight: '3.5rem'}
        });
    };

    disableLoading = () => {
        this.setState({
            loading: false,
            loadingButtonStyle: {paddingRight: '2.5rem'}
        });
    };

    render() {
        const {intl} = this.props;
        const {mfa, loading, loadingButtonStyle} = this.state;


        return (
            <>
                <div className='kt-login__head'>
        <span className='kt-login__signup-label'>
          Don't have an account yet?
        </span>
                    &nbsp;&nbsp;
                    <Link to='/auth/registration' className='kt-link kt-login__signup-link'>
                        Sign Up!
                    </Link>
                </div>

                <div className='kt-login__body'>
                    <div className='kt-login__form'>
                        <div className='kt-login__title'>
                            <h3>
                                <FormattedMessage id='AUTH.LOGIN.TITLE'/>
                            </h3>
                        </div>

                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                                mfa: ""
                            }}
                            validate={values => {
                                const errors = {};

                                if (!values.email) {
                                    errors.email = intl.formatMessage({
                                        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
                                    });
                                }

                                if (!values.password) {
                                    errors.password = intl.formatMessage({
                                        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
                                    });
                                }

                                return errors;
                            }}
                            onSubmit={(values, {setStatus, setSubmitting}) => {
                                this.enableLoading();
                                setTimeout(() => {
                                    crud.User.preLogin(values.email).then(res=>{
                                        if(res.allowedClassifications.length===1){
                                            crud.User.login(values.email,  values.password, res.allowedClassifications[0], mfa, values.mfa)
                                                .then(({error, response, responseBody}) => {
                                                    const accessTokenObject = JSON.parse(response.body);
                                                    if (accessTokenObject.hasOwnProperty('error') && accessTokenObject.error === 'mfa_required') {
                                                        this.setState({
                                                            mfa: accessTokenObject.mfa_token
                                                        });
                                                    } else {
                                                        this.props.login(JSON.parse(response.body));
                                                    }
                                                    setSubmitting(false);
                                                    this.disableLoading();
                                                })
                                                .catch((error) => {
                                                    console.log('AccessToken', error);
                                                    this.disableLoading();
                                                    setSubmitting(false);
                                                    let statusMessage = 'AUTH.VALIDATION.INVALID_LOGIN';
                                                    if (error.body && JSON.parse(error.body).error_description == "User is disabled") {
                                                        statusMessage = 'AUTH.VALIDATION.SUSPENDED_USER';
                                                    }
                                                    setStatus(
                                                        intl.formatMessage({
                                                            id: statusMessage
                                                        })
                                                    );
                                                });
                                        }
                                        else{
                                            //todo megkell csinálni
                                        }
                                    })



                                }, 1000);
                            }}
                        >
                            {({
                                  values,
                                  status,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting
                              }) => (
                                <form
                                    noValidate={true}
                                    autoComplete='off'
                                    className='kt-form'
                                    onSubmit={handleSubmit}
                                >
                                    {status && (
                                        <div role='alert' className='alert alert-danger'>
                                            <div className='alert-text'>{status}</div>
                                        </div>
                                    )}

                                    {mfa != null && (
                                        <div role='alert' className='alert alert-info'>
                                            <div className='alert-text'>
                                                For login please give the MFA code too!
                                            </div>
                                        </div>
                                    )}

                                    <Fragment style={{display: mfa == null ? 'block' : 'none'}}>
                                        <div className='form-group'>
                                            <TextField
                                                type='email'
                                                label='Email'
                                                margin='normal'
                                                className='kt-width-full'
                                                name='email'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.email}
                                                helperText={touched.email && errors.email}
                                                error={Boolean(touched.email && errors.email)}
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <TextField
                                                type='password'
                                                margin='normal'
                                                label='Password'
                                                className='kt-width-full'
                                                name='password'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.password}
                                                helperText={touched.password && errors.password}
                                                error={Boolean(touched.password && errors.password)}
                                            />
                                        </div>
                                    </Fragment>

                                    <div className='form-group' style={{display: mfa != null ? 'block' : 'none'}}>
                                        <TextField
                                            type='mfa'
                                            label='MFA code'
                                            margin='normal'
                                            className='kt-width-full'
                                            name='mfa'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.mfa && errors.mfa}
                                            error={Boolean(touched.mfa && errors.mfa)}
                                        />
                                    </div>

                                    <div className='kt-login__actions'>
                                        <Link
                                            to='/auth/forgot-password'
                                            className='kt-link kt-login__link-forgot'
                                        >
                                            <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON'/>
                                        </Link>

                                        <button
                                            id='kt_login_signin_submit'
                                            type='submit'
                                            disabled={isSubmitting}
                                            className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                                {
                                                    'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading
                                                }
                                            )}`}
                                            style={loadingButtonStyle}
                                        >
                                            Sign In
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </>
        );
    }

}

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Login)
);
